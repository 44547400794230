import React, { useState, useEffect } from 'react';
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import DesignSystem from './DesignSystem';
import SEO from '../SEO';
import BgChange from "../BgChange";
import { Container } from "../Grid";
import BlogLayout from './BlogLayout';
import Dashed from './Dashed';
import BlogImage from './BlogImage';
import Toc from './Toc';



const PostLayout = ({ children, data }) => {
  const TitleHeading = DesignSystem.H1;
  const { title, author, date, image, description, minutes } = data.mdx.frontmatter;
  const [vw, setVw] = useState();

  // change state on resize
  useEffect(() => {
    const handleResize = () => {
      setVw(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      // clean up the event handler when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, [vw]);

  useEffect(() => {
      setVw(window.innerWidth);
  }, [])

  const components = {
    h1: DesignSystem.H1,
    h2: props => (<DesignSystem.H2
                    id={props.children
                             .replace(/[^\w\s]/gi, '')
                             .split(' ').join('-')
                             .toLowerCase()
                    }
                    {...props}
                    />),
    h3: DesignSystem.H3,
    h4: DesignSystem.H4,
    p:  DesignSystem.P,
    pre: props => (<div {...props}/>),
    code: DesignSystem.C,
    a: DesignSystem.A,
    Dashed: Dashed,
    Intro: DesignSystem.Intro,
    Image: DesignSystem.Image,
  }

  return (
    <BlogLayout>
      <SEO
          title={title}
          description={description}
          />
      <BgChange initial="text-default" apply="bg-default text-dark" className="py-32 text-md w-full"
          style={{
              transition: '.33s background, .66s color',
          }}
          >
        <Container>
          <div
              className="relative tracking-wider leading-loose font-medium relative"
              style={{maxWidth: `40em`}}
              >
            {  !!(vw > 900 & data.mdx.tableOfContents.items !== undefined)  &&
              (<div
                className="absolute"
                style={{top:0, bottom:0, right: vw < 1280 ? `0` : '-4em'}}
                >
                <Toc
                  toc={data.mdx.tableOfContents.items}
                  minify={vw < 1280}
                  className="fixed"
                  />
              </div>)
            }
            <p className="text-gray-600">{date}</p>
            <TitleHeading>{title}</TitleHeading>
            {
                minutes &&
                (<p className="text-gray-600">Author: {author} · {minutes} minute read</p>)
            }
            <a
              href="/blog"
              className=" mt-8 mb-12 inline-block "
              >
              ⭠ <span className="border-b border-default">BACK TO POSTS</span>
            </a>

            <BlogImage className="w-full" image={image}/>
            <div className="text-base">
              <MDXProvider components={components}>
                <MDXRenderer>
                  {data.mdx.body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </Container>
      </BgChange>
    </BlogLayout>
  );
}

export default PostLayout;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        author
        minutes
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`
