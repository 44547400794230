import React, { useEffect, useState } from 'react';
import { Link } from "react-scroll";

// table of contents link component
const TocLink = ({isActive, url, children, minify}) => {
  return (
    <li className="relative py-2">
      <div
        className={(isActive ? "text-dark" : "text-gray-500")}
        style={{transition: 'all .2s'}}>

        <Link
          className="cursor-pointer"
          offset={-100}
          smooth={true}
          to={url}
          >
          {
            !minify && (<span className="hidden lg:block ml-4 overflow-hidden" style={{width:`10em`}}>
              {children}
            </span>)
          }
        </Link>
      </div>
    </li>
  );
}

// table of contents component
export default ({toc, minify, ...props}) => {
  // state for tracking scrolling and currently active link
  const [scrolled, setScrolled] = useState(false);
  const [active, setActive] = useState(-1);
  const [next, setNext] = useState(0);

  /*
  Making a new tableOfContets
  using .getElementById is not idiomatic in React, but operating only
  on refs can yield incorrect results when it comes to determining
  element positioning. However, the main reason to do it this way is that
  rendering of headings is handled entirely by MDXRenderer, which leaves
  us with little information on when and how they will be rendered.
  */
  const tableOfContents = toc.map(el => {
    const id = el.url.substring(1);
    return {
      title: el.title,
      id: id,
      element: document.getElementById(id),
    }
  });

  const getActive = (offset=250) => {

    return tableOfContents.findIndex((cv, i, arr) => {
      /*
      lack of next element means window is scrolled to the bottom
      to prevent the last link being highlighted we check if window
      is scrolled passed the first toc element
      */

      if (!arr[i+1] & cv.element !== null) {
        return cv.element.getBoundingClientRect().top < getWindowHeight();
      }

      // safe check in the case element wasn't queried yet
      if (!arr[i+1] || !arr[i+1].element || !cv.element) {
        return false;
      }

      const elementPosition = cv.element.getBoundingClientRect().top;
      const nextElementPosition = arr[i+1].element.getBoundingClientRect().top;

      // is window scrolled between two  elements?
      return elementPosition - offset < 0 && nextElementPosition - offset > 0;
    }, -1);
  }

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (hasScrolledToBottom()) {
          setActive(tableOfContents.length - 1);
          return
      }
      const nextActive = getActive();
      if (active !== nextActive) {
        setActive(nextActive);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    }
}, [active]);

  const hasScrolledToBottom = () => {

      const scrollPosition = document.body.scrollHeight;
      const bottomBoundPosition = window.pageYOffset + window.innerHeight;
      return scrollPosition <= bottomBoundPosition;
  }

  const getWindowHeight = () => {
    return window.innerWidth;
  }

  return (
    <div {...props}>
      {
        !minify && (<p className="mb-4 text-lg hidden lg:block">
          Table of Contents
        </p>)
      }

      <div className="relative overflow-hidden">
          <span className="absolute w-1 bg-gray-500 h-full opacity-25 rounded"/>
          { true && <span className="absolute w-1 bg-brand rounded"
              style={{
                  height: `${100/tableOfContents.length}%`,
                  top: `${100/tableOfContents.length*active}%`,
                  transition: `top .2s`,
              }}/> }
          <ul className="ml-2 leading-tight tracking-tight">
            {
              tableOfContents.map((el, i) => {
                return ( el && <TocLink
                                isActive={i===active}
                                url={el.id}
                                key={i}
                                minify={minify}
                                >
                                {el.title}
                              </TocLink>)
              })
            }
          </ul>
      </div>
    </div>
  )
}
