import React, { useEffect, useRef, useState } from 'react';


const BgChange = ({ children, apply="", initial="bg-dark", className, ...other}) => {
    const [change, setChange] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            var scrolledPast = elementRef.current.getBoundingClientRect().top;
            if ((scrolledPast < 0) !== change) {
                setChange(!change);
            }
        }

        document.addEventListener('scroll', handleScroll, { passive: true});
        return () => document.removeEventListener('scroll', handleScroll);

    }, [change]);

    return (
        <div
            ref={elementRef}
            className={ ['w-full', change ? apply : initial, className].join(' ')}
            {...other }
            >
            { children }
        </div>
    )
}

export default BgChange;
