import React from 'react';
import Highlight, {defaultProps} from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwl';


// getting rid of italic font style
const stylesNoItalics = theme.styles.map((obj) => {
  delete obj.style.fontStyle;
  return obj;
});

const highlightProps = {
  ...defaultProps,
  theme: {
    ...theme,
    styles: stylesNoItalics,
  },
}


export default ({children, className }) => {
  if (className === undefined) {
    return (
      <code className="font-light">{children}</code>
    );
  };
  const language = className.replace(/language-/, '');

  return (
    <Highlight {...highlightProps} code={children.trim()} language={language} >
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={{...style}}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
