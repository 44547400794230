import React from 'react';
import CodeBlock from './CodeBlock';
import BlogImage from './BlogImage';

const DesignSystem = {
  H1:  props => (<h1 className="uppercase text-2xl xs:text-3xl md:text-5xl leading-tight  font-bold" {...props}/>),
  H2:  props => (<h2 className="text-2xl leading-tight md:text-4xl max-w-md font-bold mt-20 mb-8" {...props}/>),
  H3: props => (<h3 className="text-xl leading-relaxed font-bold mt-12 mb-4" {...props} />),
  H4: props => (<h4 className="text-md font-bold leading-relaxed font-medium mb-2" {...props} />),
  P: props =>   (<p className="text-gray-800 pb-4" {...props}/>),
  A: props => (<a className="underline" {...props}/>),
  C: props => (<div className="my-8"><CodeBlock {...props}/></div>),
  Intro: props => (<div className="text-md my-12 sm:text-2xl md:my-24 font-light text-black leading-loose " {...props}/>),
  Image: BlogImage,
}

export default DesignSystem;
